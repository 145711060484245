define(["jquery","./SearchBar","google.maps.async","underscore"],function($,SearchBar,mapsAsync,_){
	return function(selector,mapdata,infoTemplate){

		var map;
		var maps;
		var info;
		var infoTmpl;
		var mapOverlaySel="#mapOverlay";

		var activeData;

		var markerImage={
			normal:'/img/map-marker.png',
			active:'/img/map-marker-active.png'
		};

		function initMap(lat,long,zoom) {
			var myOptions = {
				center: new maps.LatLng(lat, long),
				zoom: zoom,
				mapTypeId: maps.MapTypeId.ROADMAP
			};
			map = new maps.Map($(selector).get(0), myOptions);

			info=new maps.InfoWindow();

		}
		function setMarker(lat, lng, link, title,data) {

			var latLng = new maps.LatLng(lat, lng);
			var image = markerImage.normal;
			var marker = new maps.Marker({
				position: latLng,
				map: map,
				icon: image,
				title: title
			});
			var obj={marker:marker,data:data};
			maps.event.addListener(marker,'click',function(){
				marker_click(obj);
			});
			return obj;
		}
		function marker_click(data){
			//data.marker.setAnimation(maps.Animation.BOUNCE);
			if (activeData) activeData.marker.setIcon(markerImage.normal);

			activeData=data;
			activeData.marker.setIcon(markerImage.active);
			$(mapOverlaySel).html(infoTmpl(data));
			$(mapOverlaySel).addClass("show");
			//info.setOptions({
			//	content:infoTmpl(data)
			//});
			//info.open(map,data.marker);
		}

		$(function(){

			var search=SearchBar.build({
				element:'.order-widget',
				pickerClass:'picker classic'
			});

			var res;
			var focusObjectId;
			if (res=/^#highlight=(\d+)$/.exec(location.hash)){
				focusObjectId=parseInt(res[1]);
			}

			var coordsMap={};
			var focusObject;
			var focusData;
			mapdata.forEach(function(item){
				if (!item.coords) return;

				var cstr=item.coords[0]+'|'+item.coords[1];
				var a=coordsMap[cstr] || [];

				a.push(item);

				coordsMap[cstr]=a;
				if (item.id==focusObjectId){
					focusObject=a;
				}
				//setMarker(item.coords[0],item.coords[1],null,item.name,item);
			});

			infoTmpl=_.template($(infoTemplate).html().replace(/^\s+/,""));
			var lat=43.3006224;
			var long=11.2460184;
			var zoom=8;
			if (focusObject){
				lat=focusObject[0].coords[0];
				long=focusObject[0].coords[1];
				zoom=10;
			}

			mapsAsync((loadedMaps) => {
				maps = loadedMaps
				initMap(lat,long,zoom);

				for (var i in coordsMap){
					var itemA=coordsMap[i];
					var item=itemA[0];

					var markerData=setMarker(item.coords[0],item.coords[1],null,item.complex,itemA);
					if (focusObject==itemA)
						focusData=markerData;
				}

				if (focusData){
					marker_click(focusData);
				}
			})

			

			$(document).on("click","[data-action=closeMapOverlay]",function(){
				$(mapOverlaySel).removeClass("show");
			});
		});


	};
});
