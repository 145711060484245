define(["jquery","./SearchBar"],function($,SearchBar){
	return function(sel){
		$(function(){
			var search=SearchBar.build({
				element:sel||'.order-widget',
				pickerClass:'picker classic'
			});
		});
	}
});
