define(['jquery','./SearchBar','google.maps.async','config','slick','semantic'],function($,SearchBar,mapsAsync,config){
	return function(){

		let maps = null
		$(function(){

			var gallerySel=".dimmer-gallery";
			var mapSel=".dimmer-map";
			var priceChoiceSel='.yearchoice a.button';
			var priceTableSel='.detail-prices';
			var initGallery=false;
			var map;

			$('.object-detail-subnav').scrollToFixed({
				zIndex:100,
				minWidth:1000
			});

			$('.object-detail-widget').scrollToFixed({
				marginTop: 58,
				zIndex:101,
				minWidth:1000
			});

			$(".object-detail-widget form").form({
				startDate:{
					identifier:'startDate',
					rules:[
						{ type:"empty", prompt:"Bitte ausfüllen" }
					]
				},
				endDate:{
					identifier:'endDate',
					rules:[
						{ type:"empty", prompt:"Bitte ausfüllen" }
					]
				},
				adults:{
					identifier:'adults',
					rules:[
						{ type:"empty", prompt:"Bitte auswählen" }
					]
				}
			},{inline:true});
			$(".object-detail-widget form").attr("novalidate","");




			var availabilty=$("#availabilty");
			var disableDates=[];
			$.getJSON('/booking/check/'+availabilty.attr("data-object-id")+'/'+availabilty.attr("data-date-start")+'/'+availabilty.attr("data-date-end"))
			.done(function(data){


				if (data.status){

					data.response.forEach(function(el,i){
						disableDates.push({from:new Date(el.startFormatted),to:new Date(el.endFormatted)});
					});

					//render function to cut in half
					function picker_render(){
						var list=$(availabilty).next().find(".picker__day");
						var lastdisabled=false;

						for (var i=0,ilen=list.length; i<ilen; i++){
							var le=list.eq(i);
							if (lastdisabled && !le.hasClass("picker__day--disabled")){
								le.addClass("picker__day--disabledafter");
								lastdisabled=false;
								continue;
							}
							if (!lastdisabled && le.hasClass("picker__day--disabled")){
								if (i>0)
									le.addClass("picker__day--disablefirst");

								lastdisabled=true;
							}
						}

					}

					var picker=$(availabilty).pickadate({
						min:new Date(parseInt(availabilty.attr("data-date-start"))*1000),
						max:new Date(parseInt(availabilty.attr("data-date-end"))*1000),
						//selectYears:true,
						disable:disableDates,
						firstDay: 1,
						weekdaysShort: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],
						monthsFull: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
						klass: {
							picker: 'picker inline-fixed',
							infocus: '',
							outfocus: '',
							selected:'',
							highlighted:''
						},
						onRender:picker_render
					}).pickadate("picker");

					var search=SearchBar.build({
						element:'.object-detail-widget',
						pickerClass:'picker classic picker-object-detail',
						pickerContainer:'form',
						slider:false,
						disableDates:disableDates
					});
				} else {
					console.error("/booking/check",data);
				}



			});



			$(document).on("click","[data-action=openGallery]",function(e){
				//$(gallerySel).removeClass("hide");
				$(gallerySel).dimmer('toggle');

				//$("html").addClass("gallery-show");

				var index=parseInt($(this).attr("data-openGallery-index")) || 0;

				if (!initGallery){
					initGallery=true;
					$(".gallerybox-slider").slick({
						slide:'div',
						asNavFor: '.gallerybox-thumbs',
						initialSlide:index,
						onBeforeChange:slick_change
					});
					var infoTmplSrc=$("#galleryboxInfobarTmpl").html().replace(/\s+/,"");
					var infoTmpl=function(){
						var res=infoTmplSrc;
						[].forEach.call(arguments,function(arg,index){
							res=res.replace("{"+index+"}",arg);
						});
						return res;
					}

					function slick_change(slick,currentIndex,nextIndex){
						$(".gallerybox-infobar").html(infoTmpl(nextIndex+1,slick.slideCount,$("img",slick.$slides[nextIndex]).attr("alt")));
					}
					slick_change($(".gallerybox-slider")[0].slick,0,0);


					if ($(window).width()>=1000){
						$(".gallerybox-thumbs").slick({
							slide:'a',
							asNavFor: '.gallerybox-slider',
							slidesToShow: 9,
							focusOnSelect: true,
							initialSlide:0,
							centerMode:true
						});
					}
					//$(".gallerybox-thumbs").find(".slick-active").not(":eq("+index+")").removeClass("slick-active");
				} else {
					$(".gallerybox-thumbs").slickGoTo(index,true);
					$(".gallerybox-slider").slickGoTo(index,true);
				}
				$(".gallerybox-thumbs").find(".slick-slide").removeClass("slick-active").eq(index).addClass("slick-active");

			});
			$(document).on("click","[data-action=closeGallery]",function(e){
				$(gallerySel).dimmer('toggle');
				$("html").removeClass("gallery-show");
			});

			$(document).on("click","[data-action=showMap]",function(e){
				//console.info("show map");
				var posStr=$(this).attr("data-args-position");
				if (posStr==undefined || posStr=="") return;
				var zoom=14;
				var pos=posStr.split(",");
				if (pos.length>2) zoom=parseInt(pos[2]);

				$(mapSel).dimmer('toggle');

				mapsAsync((loadedMaps) => {
					maps = loadedMaps
					initMap(pos[0],pos[1],zoom);
					setMarker(pos[0],pos[1], null, "House");
					setTimeout(function(){
						$(".dimmer-map-map",mapSel).css("position","");
					},500);
				})
			});
			$(document).on("click","[data-action=hideMap]",function(e){
				$(mapSel).dimmer('toggle');
				$(".dimmer-map-map",mapSel).empty();
			});

			$(document).on("click", priceChoiceSel, function(e) {
				var year = $(e.currentTarget).attr("data-value");
				$(priceChoiceSel).removeClass("active").filter("[data-value='"+year+"']").addClass("active");
				$(priceTableSel).find("tr").hide().filter("[data-filter-year='"+year+"']").show();
			});

			function initMap(lat,long,zoom) {
				var myOptions = {
					center: new maps.LatLng(lat, long),
					zoom: zoom,
					mapTypeId: maps.MapTypeId.ROADMAP
				};
				map = new maps.Map($(".dimmer-map-map",mapSel).get(0), myOptions);
			}
			function setMarker(lat, lng, link, title) {

				var latLng = new maps.LatLng(lat, lng);
				var image = '/img/map-marker.png';
				var marker = new maps.Marker({
					position: latLng,
					map: map,
					icon: image,
					title: title
				});
			}


		});
	};
});
