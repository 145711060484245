define(['jquery'], function($) {

  const ACTION = 'data-action'
  const TARGET = 'data-target'
  const ARGS = 'data-args'
  $(document).on('click', `[${ACTION}]`, onAction)

  function onAction(e) {
    let current = $(e.currentTarget)
    let action = { 
      current,
      action: current.attr(ACTION),
      target: current.attr(TARGET),
      args: current.attr(ARGS),
    }

    console.log('action', action)
    if (action.action == 'toggle') {
      onToggle(e, action)
    }
  }
  function onToggle(e, { target, args, current }) {
    $(target).toggleClass(args)
    // if (args.includes('hide')) {
    //   current.hide()
    // }
  }
})