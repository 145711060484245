define(['jquery','picker.date','jquery.nouislider','jquery.liblink'],function($){

	function SearchBar(){
		var that=this;
		var defaultOpts={
			element:'form.widget:eq(1)',
			startPicker:'.picker-classic[name=startDate]',
			endPicker:'.picker-classic[name=endDate]',
			pickerClass:'picker',
			priceSlider:'#priceSlider',
			priceSliderMin:'[name=minPrice]',
			priceSliderMax:'[name=maxPrice]',
			priceSliderMinLabel:'#minPriceLabel',
			priceSliderMaxLabel:'#maxPriceLabel',
			pickers:true,
			slider:true,
			disableDates:[],
			defaultPickerOptions:{
				formatSubmit:"yyyy-mm-dd",
				format:"dd.mm.yyyy",
				today:"Heute",
				clear:"Löschen",
				close:"Schliessen",
				firstDay: 1,
				weekdaysShort: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],
				monthsFull: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ]
			}
		};
		var options;

		var startPicker;
		var endPicker;
		var context;
		var currency;

		function init(opts){
			options=$.extend({},defaultOpts,opts);
			context=$(options.element||document);

			if (options.pickers) initPickers();
			if (options.slider) initSlider();
		}
		function initPickers(){
			//console.info("picker container",options.pickerContainer?$(options.startPicker,context).parents(options.pickerContainer):undefined);
			startPicker=$(options.startPicker,context).pickadate($.extend({},options.defaultPickerOptions,{
				min:true,
				disable:options.disableDates,
				klass: {
					picker: options.pickerClass
				},
				container:options.pickerContainer?$(options.startPicker,context).parents(options.pickerContainer):undefined,
				onOpen: function() {
					$(".ui.dropdown").dropdown("hide");
				}
			})).pickadate("picker");
			console.info("picker", startPicker)
			fixupPicker(startPicker);

			$(options.startPicker,context).parent().find("i").on("click",function(){
				setTimeout(function(){
					if (startPicker.get("open")) startPicker.close();
					else startPicker.open();
				},0);
			});

			endPicker=$(options.endPicker,context).pickadate($.extend({},options.defaultPickerOptions,{
				min:1,
				klass: {
					picker: options.pickerClass
				},
				container:options.pickerContainer?$(options.startPicker,context).parents(options.pickerContainer):undefined,
				onOpen: function() {
					$(".ui.dropdown").dropdown("hide");
				}
			})).pickadate("picker");
			fixupPicker(endPicker);

			$(options.endPicker,context).parent().find("i").on("click",function(){
				setTimeout(function(){
					if (endPicker.get("open")) endPicker.close();
					else endPicker.open();
				},0);
			});

			startPicker.on("set",picker_set);
		}
		function picker_set(newObj){
			var end=endPicker.get('select');
			var pickedTs=newObj.select;

			if (pickedTs==null){
				endPicker.set("min",new Date(Date.Now+3600*24));
				endPicker.set("max",false);
				endPicker.clear();
				return;
			}
			var nextDay=new Date(newObj.select+3600000*24);


			endPicker.set("min",nextDay);

			endPicker.set("max",new Date(nextDay.getTime()+90*24*3600000));
			for (var i=0,ilen=options.disableDates.length; i<ilen; i++){
				var el=options.disableDates[i];
				if (el.from.getTime()>pickedTs){
					endPicker.set("max",el.from);
					break;
				}
			}

			if (!end || end.obj.getTime()<nextDay){
				endPicker.set("select",nextDay);
			}
		}
		function fixupPicker(picker) {
			var v = picker.$node.val();
			if (v)
				picker.set('select', v, { format: 'yyyy-mm-dd' });
		}
		function initSlider(){
			var priceSlider=$(options.priceSlider,context);
			priceSlider.noUiSlider({
				start: [$(options.priceSliderMin,context).val()||0, $(options.priceSliderMax,context).val()|| 8000],
				connect: true,
				range: {
					'min': parseInt(priceSlider.attr("data-price-min")) || 0,
					'max': parseInt(priceSlider.attr("data-price-max")) || 8000
				},
				step:100
			});

			currency=priceSlider.attr("data-price-currency");

			priceSlider.Link('lower').to($(options.priceSliderMin,context));
			priceSlider.Link('upper').to($(options.priceSliderMax,context));

			priceSlider.Link('lower').to($(options.priceSliderMinLabel,context),priceFormat);
			priceSlider.Link('upper').to($(options.priceSliderMaxLabel,context),priceFormat);


		}
		function priceFormat(value){
			$(this).html(Math.floor(value).toFixed()+" "+currency);
		}

		this.init=init;
	}
	SearchBar.build=function(){
		var c=new SearchBar();
		c.init.apply(c,[].slice.call(arguments));
		return c;
	};
	return SearchBar;
});
