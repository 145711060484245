define(["jquery","./mobilesidebar"/* ,"registerAll"*/,"./analytics"],function($,mobilesidebar){

	mobilesidebar();

	$(function(){
		$('.ui.checkbox').checkbox();
		$('.ui.dropdown').dropdown();

		$(document).on("click","[data-action=toggleRemember]",function(){

			var t=$(this);
			var active=t.hasClass("active");



			if (active){
				$.getJSON('/merkliste/remove?id='+$(this).attr("data-toggleRemember-id"))
				.done(function(){
					t.html('<i class="heart empty icon"></i> Auf die Merkliste setzten');

				});
				t.removeClass("active");
			} else {
				$.getJSON('/merkliste/add?id='+$(this).attr("data-toggleRemember-id"))
				.done(function(){

					t.html('<i class="heart icon"></i> Von der Merkliste nehmen');
				});
				t.addClass("active");

			}

		});

		$('#currencySwitch').on('change', function(){
			if (window.config) {
				window.location.href = window.config.currencySwitch.replace('REPLACE', $(this).val());
			}
		});
	});

});
