define(['jquery', 'google.maps.async'], function ($, googlemaps) {
	var map;

	function initMap() {
		var myOptions = {
			center: new google.maps.LatLng('46.858313', '7.594299'),
			zoom: 8,
			mapTypeId: google.maps.MapTypeId.ROADMAP
		};
		map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
	}

	function setMarker(lat, lng, location, link, title) {

		var latLng = new google.maps.LatLng(lat, lng);
		var image = '/tl_files/supermauro/img/marker.png';
		var marker = new google.maps.Marker({
			position: latLng,
			map: map,
			icon: image,
			title: title
		});
	}


	function mapsReady () {
		if (!$("html").hasClass("device-desktop") && !$("html").hasClass("device-tablet")) return;

		initMap();
		setMarker(47.498122, 8.724797, 'Filiale', '#filiale-winterthur', 'Filiale Winterthur');
		setMarker(47.414973, 8.619829, 'Filiale', '#filiale-dietlikon', 'Filiale Dietlikon-ZH');
		setMarker(47.227589, 8.818411, 'Filiale', '#filiale-rapperswil', 'Filiale Rapperswil');
		setMarker(47.376593, 8.54066, 'Filiale', '#filiale-zuerich', 'Filiale Zürich');
		setMarker(46.52287, 6.62703, 'Filiale', '#filiale-lausanne', 'Filiale Lausanne');
		setMarker(47.462683, 9.04091, 'Filiale', '#filiale-wil', 'Filiale Wil');
		setMarker(47.133494, 7.2422, 'Filiale', '#filiale-biel', 'Filiale Biel');
		setMarker(47.3629364, 7.3498796, 'Filiale', '#filiale-delemont', 'Filiale Delémont');
		setMarker(47.5621962, 7.5919313, 'Filiale', '#filiale-basel', 'Filiale Basel');
		setMarker(47.4102037, 8.5432094, 'Filiale', '#filiale-oerlikon', 'Filiale Oerlikon');
		setMarker(47.0518222, 8.303902, 'Filiale', '#filiale-luzern', 'Filiale Luzern');
		setMarker(46.9917289, 6.9300986, 'Filiale', '#filiale-neuchatel', 'Filiale Neuchâtel');
	}

	return function() {
		googlemaps(mapsReady);
	}
});
