define(['jquery','./SearchBar'],function($,SearchBar){
	return function(selector){
		$(function(){
			$('.sidebar.booking').css('display', '')
			$('.sidebar.booking')
			.sidebar({
				overlay: true,
				useCSS: true
			}).sidebar('attach events', 'a[data-action=toggleBooking]');

			$('.sidebar.menu').css('display', '')
			$('.sidebar.menu')
			.sidebar({
				overlay: true,
				useCSS: true
			}).sidebar('attach events', 'a[data-action=toggleMenu]');

			// var search=SearchBar.build({
			// 	element:'.sidebar form.widget:eq(0)',
			// 	pickers:false
			// });


		});
	};
});
