function initAutocomplete(el,opts){
	function lookup(q,cb){
		var url="/admin/page/ajax/objects/"+encodeURIComponent(q);
		if (window.location.pathname.indexOf("/app_dev.php")==0)
			url="/app_dev.php"+url;

		$.getJSON(url).then(cb);
	}

	el.autocomplete({
		lookup: lookup,
		onSelect: function (suggestion) {
			$(this).val(suggestion.data);
			var name=$(this).next();
			if (name) name.remove();
			$(this).after(name=$(opts.urlTmpl));
			name.find("a").text(suggestion.value);
			name.find("a").attr("href",name.find("a").attr("data-href").replace("99999",suggestion.data));

		}
	})
}

$(function(){
    $('.add').on('click', function(e){
        var el = $(e.target);
        var label = el.data('label') == undefined ? '' : '<label>&nbsp;</label>';
        var priceContainer = el.parent().find('.form-group');
        var template = el.parent().find('script').html();
				var classes=el.parent().find('script').attr("data-outerclass");
		var currentNumPrices = 0;
        priceContainer.find('> div input').each(function(k, v){
			var num = $(v).attr('name').match(/\[([0-9]+)\]/)[1];
			if(parseInt(num) > currentNumPrices){
				currentNumPrices = num;
			}
		});
		currentNumPrices++;
        template = template.replace(/__name__/g, currentNumPrices);
        //template = template.replace(/__name__/g, '');
        template = $(template).html(); // remove outer most div
        template = $(template).append('<div>'+ label +'<a href="javascript:{}" class="remove">Entfernen</a></div>');
        $('select[name*="year"]', template).val(new Date().getFullYear() + 1);

				if (classes){
					template.addClass(classes);
				}

        priceContainer.append(template);

				var completeEl=template.find("input");
				if (completeEl.attr("name").indexOf("objectid")>-1){
					initAutocomplete(completeEl,{urlTmpl:$("#addPageObjectUrl").html()});
				}

    });
    $('.panel-body').on('click', '.remove', function(e){
        $(e.target).parent().parent().remove();
    });
    $('.sortable').sortable({
        update: function(event, ui){
            $(this).find('> div').each(function(divKey, div){
                $(div).find('input').each(function(k, v){
                    var el = $(v);
                    el.attr('name', el.attr('name').replace(/\[[0-9]+\]/g, '['+divKey+']'));
                });
            });
        }
    });

		var autosel=$(".form-object input");
		if (autosel.length) {
			autosel.each(function(){
				initAutocomplete($(this),{urlTmpl:$("#addPageObjectUrl").html()});
			});
		}


});

var editorOptions = { // (required) ID of the textarea
	width:'100%', // (optional) width of the editor
	//height:175, // (optional) heightof the editor
	//cssclass:'te', // (optional) CSS class of the editor
	controlclass:'tinyeditor-control', // (optional) CSS class of the buttons
	//rowclass:'teheader', // (optional) CSS class of the button rows
	//dividerclass:'tedivider', // (optional) CSS class of the button diviers
	controls:['bold', 'italic', 'underline', 'strikethrough', 'style', '|', 'unorderedlist', 'unformat', '|', 'undo', 'redo', 'link', 'unlink'], // (required) options you want available, a '|' represents a divider and an 'n' represents a new row
	//controls:['bold', 'italic', 'underline', 'strikethrough', '|', 'subscript', 'superscript', '|', 'orderedlist', 'unorderedlist', '|' ,'outdent' ,'indent', '|', 'leftalign', 'centeralign', 'rightalign', 'blockjustify', '|', 'unformat', '|', 'undo', 'redo', 'n', 'font', 'size', 'style', '|', 'image', 'hr', 'link', 'unlink', '|', 'print'], // (required) options you want available, a '|' represents a divider and an 'n' represents a new row
	footer:true, // (optional) show the footer
	//fonts:['Verdana','Arial','Georgia','Trebuchet MS'],  // (optional) array of fonts to display
	//xhtml:true, // (optional) generate XHTML vs HTML
	cssfile:'/lib/tinyeditor/tinycontent.css', // (optional) attach an external CSS file to the editor
	//content:'starting content', // (optional) set the starting content else it will default to the textarea content
	//css:'body{background-color:#ccc}', // (optional) attach CSS to the editor
	//bodyid:'editor', // (optional) attach an ID to the editor body
	//footerclass:'tefooter', // (optional) CSS class of the footer
	toggle:{text:'source',activetext:'wysiwyg',cssclass:'toggle'}, // (optional) toggle to markup view options
	resize:{cssclass:'resize'} // (optional) display options for the editor resize
};

function createEditors(ids){
	var editors = [];
	for(i in ids){
		if(ids.hasOwnProperty(i)){
			var tmp = $.extend({}, editorOptions);
			tmp.id = ids[i];
			editors.push(new TINY.editor.edit('editor' + i,tmp));
		}
	}
	return editors;
}
