define(["jquery","slick"],function($){
	return function(selector){
		$(function(){
			$(selector).slick({
				dots:true,
				autoplay: true,
				autoplaySpeed: 5000
			});
		});
	};
});