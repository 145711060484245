define(['jquery','underscore'],function($,_){
	return function(){
		var hideClass="hideObject";
		var dataKey="hiddenObjects";
		var objectIdKey="data-object-id";
		var itemSelector='.object-overview-item';

		var hiddenItems={};
		var tmpl;


		var data;
		if (window.localStorage && (data=window.localStorage.getItem(dataKey))!=null){
			hiddenItems=JSON.parse(data) ;
		}

		$(document).on('click','[data-action=toggleObject]',function(e){
			var t=$(this);
			var p=t.parents(itemSelector);
			var id=p.attr(objectIdKey);

			if (p.hasClass(hideClass)){
				hiddenItems[id]=0;
				p.removeClass(hideClass);
			} else {
				hiddenItems[id]=1;
				p.addClass(hideClass);

				appendHiddenContainer(p,id);
			}

			saveStorage();
		});
		$(document).on('click','[data-action=showObject]',function(e){
			var t=$(this);
			var id=t.attr("data-args");
			var sel=$(itemSelector+"["+objectIdKey+"="+id+"]");
			sel.removeClass(hideClass);

			t.parents(itemSelector).remove();

			hiddenItems[id]=0;

			saveStorage();
		});

		$(function(){
			tmpl=_.template($("#hiddenTmpl").html().replace('^\s+',''));

			$(itemSelector).each(function(i,el){
				var t=$(this);
				var id=t.attr("data-object-id");
				if (hiddenItems[id]===1){
					t.addClass(hideClass);
					appendHiddenContainer(t,id);
				}
			});

		});

		function appendHiddenContainer(t,id){
			var html=tmpl({object:{id:id}});
					t.after($(html));
		}

		function saveStorage(){
			if (window.localStorage){
				window.localStorage.setItem(dataKey,JSON.stringify(hiddenItems));
			}
		}
	};
});
