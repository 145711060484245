import('./js/mainsite.js')
import('./less/main.less')

let dynRequire = require.context('./js', true, /\.js$/)
let appMap = dynRequire.keys().reduce((p, v) => {
  p[v] = dynRequire(v)
  return p
}, {})

window.execApp = function (deps, fnc) {
  let args = deps.map(v => appMap[`./${v}.js`])
  console.log("execute", args)
  fnc(...args)
}
if (window.execAppTmp) {
  window.execAppTmp.forEach(v => window.execApp(v.deps, v.fnc))
}