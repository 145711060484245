define(['jquery','./SearchBar','slick'],function($,SearchBar){
	return function(){
		$(function(){

			var search=SearchBar.build({
				element:'.order-widget',
				pickerClass:'picker classic'
			});


			//slick integration for list images
			$(document).on('mouseenter','.object-overview-image-slider',function(e){
				var t=$(this);

				if(!t.data("slide-active")){
					//console.info("hover");

					t.slick({
						slide: 'a',
						lazyLoad: 'ondemand',
						slidesToScroll: 1,
						pauseOnHover: false,
						autoplaySpeed:2000,
						dots:false,
						prevArrow:false,
						nextArrow:false

					});
					t.data("slide-active",true);
				}
				t.slickPlay();
			});
			$(document).on('mouseleave','.object-overview-image-slider',function(e){
				var t=$(this);

				if(t.data("slide-active")){
					t.slickPause();
				}
			});


		});
	};
});
