define(['jquery'],function($){
	if (typeof ga == "function"){
		$(document).on("click","[data-track]",function(e){
			var track=$(this).attr("data-track");
			var split=track.split(",");


			var label=split[0];
			var action='click';
			var category='button';
			if (split.length==3){
				label=split[2];
				action=split[1];
				category=split[0];
			}



			if ($(this).is("a[href]")){
				var href=$(this).attr("href");
				if (href.indexOf("java")!=0){
					e.preventDefault();
					ga('send', 'event', category, action, label,{hitCallback:function(){
						document.location=href;
					}});
					return;
				}
			}

			ga('send', 'event', category, action, label);
		});

		$(document).on("click","[data-track-page]",function(){
			var url=$(this).attr("data-track-page");

			ga('send','pageview', url);
		});
	}
});
