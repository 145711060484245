define([], function() {
	var loaded = false;
	var injected = false;
	var listeners = [];
	var apikey = "AIzaSyDFFA4aKt1VN8sAXrxOkUMt6KT4TVQZE94";

	function injectScript() {
		//inject script and wait
		injected = true;

		var script=document.createElement("script");
		var cb="googlemaps_cb_"+Math.random().toString().replace(".","");
		script.src="//maps.google.com/maps/api/js?v=3&key="+apikey+"&callback="+cb;
		script.async=true;
		window[cb]=function(){
			loaded = true;
			listeners.forEach(function(l) {
				l && l(window.google.maps);
			});
		};
		document.getElementsByTagName('head')[0].appendChild(script);
	}

	return function(fnc) {
		if (!loaded) {
			listeners.push(fnc);
		} else {
			fnc(window.google.maps);
		}
		if (!injected) {
			injectScript();
		}
	}
});
