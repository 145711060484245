define(['jquery','./SearchBar'],function($,SearchBar){
	return function(){
		$(function(){
			var search=SearchBar.build({
				element:'form.widget:eq(1)',
				pickerClass:'picker classic'
			});


		});
	};
	
});
