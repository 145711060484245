define(["jquery"],function($){
	var defaultOpts={
	};
	return function(sel,opts){
		$(function(){

			//autofind
			var autofind={};
			function addValidator(elem,field,type,prompt){
				var fieldObj=autofind[field] ||{identifier:field,rules:[]};
				var rules=fieldObj.rules;
				rules.push({
					type:type,
					prompt:prompt||elem.attr("data-sv-"+type+"-msg")
				});
				autofind[field]=fieldObj;
			}
			var fields=$(sel).find("input[name],textarea[name],select[name]");
			fields.each(function(index){
				var t=$(this);
				if (t.attr("required")!==undefined){
					addValidator(t,t.attr("name"),"empty");
				}
				if (t.attr("type")=="email"){
					addValidator(t,t.attr("name"),"email");
				}
			});

			console.info("autofind",autofind);
			$(sel).form($.extend(true,{},defaultOpts,autofind,opts),{inline:true,onFailure:function(){
				//FIX: added dropdown on error selection fix
				$(".error",sel).find(".dropdown > a,input[type!=hidden]").eq(0).focus();
				return false;
			}});
			$(sel).attr("novalidate","");

		});
	};
});
